import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/common_buff',
        name: 'CommonBuff',
        component: () => import(/* webpackChunkName: "CommonBuff" */ '../views/CommonBuff.vue')
    },
    {
        path: '/types_buff',
        name: 'TypesBuff',
        component: () => import(/* webpackChunkName: "TypesBuff" */ '../views/TypesBuff.vue')
    },
    {
        path: '/common_talent',
        name: 'CommonTalent',
        component: () => import(/* webpackChunkName: "CommonTalent" */ '../views/CommonTalent.vue')
    },
    {
        path: '/types_talent',
        name: 'TypesTalent',
        component: () => import(/* webpackChunkName: "TypesTalent" */ '../views/TypesTalent.vue')
    },
    {
        path: '/random_challenge',
        name: 'RandomChallenge',
        component: () => import(/* webpackChunkName: "RandomChallenge" */ '../views/RandomChallenge.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
