<template>
    <router-view />
</template>

<script setup>

</script>

<style lang="scss">
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    -o-tap-highlight-color: transparent;
}

body {
    font-family: Helvetica, Arial, "Noto Sans", sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-synthesis: style;
    min-height: 100vh;
}
</style>
