<script setup >
import { useRouter } from 'vue-router'
let Router = useRouter()
</script>

<template>
    <section class="hero">
        <div class="main">
            <h1 class="name">
                <span class="clip">穿越火线高清大区</span>
            </h1>
            <p class="text">挑战模式BUFF</p>
            <p class="tagline">更新版本：2024年1月 穿越赛博坦</p>
        </div>
        <div class="image">
            <img class="image_src" src="../../public/favicon.svg" alt="image alt">
        </div>
    </section>

    <section class="features">
        <article class="box" @click="Router.replace('/common_buff')">
            <h2 class="title">通用BUFF</h2>
            <p class="details">对局内所有兵种通用BUFF</p>
        </article>
        <article class="box" @click="Router.replace('/types_buff')">
            <h2 class="title">兵种BUFF</h2>
            <p class="details">对局内对应兵种BUFF</p>
        </article>
        <article class="box" @click="Router.replace('/common_talent')">
            <h2 class="title">通用天赋</h2>
            <p class="details">对局外通用的天赋</p>
        </article>
        <article class="box" @click="Router.replace('/types_talent')">
            <h2 class="title">兵种天赋</h2>
            <p class="details">对局外对应的兵种天赋</p>
        </article>
    </section>
</template>

<style lang="scss" scoped>
.hero {
    display: flex;
    flex-direction: row;
    margin: 50px auto 0;
    max-width: 1152px;
    padding: 50px;

    .main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name,
        .text {
            font-size: 54px;
            line-height: 66px;
            font-weight: 700;
            white-space: pre-wrap;

            .clip {
                -webkit-text-fill-color: #2ea0f6;
            }
        }

        .name {
            margin-top: 16px;
        }

        .tagline {
            padding-top: 12px;
            line-height: 36px;
            font-size: 24px;
            color: rgba(60, 60, 67, 0.78);
        }
    }

    .image {
        user-select: none;
        text-align: center;
        flex: 1;

        img {
            width: 280px;
            height: 280px;
            -webkit-user-drag: none;
        }
    }
}

.features {
    user-select: none;
    max-width: 1152px;
    margin: 0 auto;
    display: flex;
    gap: 16px;
    padding: 0 50px 20px;

    .box {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 24px;
        border: 1px solid #f6f6f7;
        background: #f6f6f7;
        border-radius: 12px;
        transition: border-color 0.25s;
        cursor: pointer;

        &:hover {
            border-color: #2ea0f6;
        }

        .title {
            line-height: 24px;
            font-size: 16px;
            font-weight: 600;
        }

        .details {
            flex-grow: 1;
            padding-top: 8px;
            line-height: 24px;
            font-size: 14px;
            font-weight: 500;
            color: #3c3c43c7;
        }
    }
}

@media (max-width: 1000px) {
    .hero {
        margin-top: 0;

        .main {
            flex: auto;
            width: 576px;
        }

        .image {
            flex: unset;

            img {
                width: 200px;
                height: 200px;
            }
        }
    }
}

@media (max-width: 850px) {
    .hero {
        padding: unset;
        padding-bottom: 20px;
        flex-direction: column-reverse;

        .main {
            width: 100%;
            align-items: center;

            .name,
            .text {
                font-size: 48px;
                line-height: 66px;
            }

            .tagline {
                font-size: 20px;
            }
        }
    }

    .features {
        flex-direction: column;
    }
}

@media (max-width: 620px) {
    .hero {
        flex-direction: column-reverse;

        .main {
            width: 100%;
            align-items: center;

            .name,
            .text {
                font-size: 32px;
                line-height: 40px;
            }

            .tagline {
                font-size: 18px;
            }
        }

        .image img {
            width: 192px;
            height: 192px;
        }

        .image .image_bg {
            width: 192px;
            height: 192px;
            left: calc(50% - 91px);
        }
    }
}
</style>
